<template>
  <footer>
    <div class="container" v-if="!$route.meta.footer_no_form">
      <div class="row">
        <div class="col-lg-3 text-center text-lg-left">
          <div class="campus-details mb-5 mb-lg-0">
            <p class="campus-title fw-600">Campus Barcelona</p>
            <p class="campus-subtitle small">
              Carrer de Guillem Tell, 27, 08006 Barcelona, España
            </p>
            <p class="mb-0 small">
              <i class="mr-2 fa-solid fa-phone text-secondary"></i>
              <a href="tel:34935151174">+34 93 515 11 74</a>
            </p>
            <p class="mb-0 small">
              <i class="mr-2 fa-brands fa-whatsapp text-secondary"></i>
              <a target="_blank" href="http://wa.me/34607595547">
                +34 664 23 27 56
              </a>
            </p>
            <p class="mb-0 small">
              <i class="mr-2 fa-regular fa-envelope text-secondary"></i>
              <a href="mailto:info@nlcollege.es">info@nlcollege.es</a>
            </p>
          </div>
        </div>
        <div class="col-lg-3 text-center text-lg-left">
          <div class="campus-details mb-7 mb-lg-0">
            <p class="campus-title fw-600">Campus Madrid</p>
            <p class="campus-subtitle small">
              Plaza Puerta del Sol 11, Segunda Planta, 28013 Madrid, España
            </p>
            <p class="mb-0 small">
              <i class="mr-2 fa-solid fa-phone text-secondary"></i>
              <a href="tel:34917376633">+34 91 737 66 33</a>
            </p>
            <p class="mb-0 small">
              <i class="mr-2 fa-brands fa-whatsapp text-secondary"></i>
              <a target="_blank" href="http://wa.me/34607595547">
                +34 664 23 27 56
              </a>
            </p>
            <p class="mb-0 small">
              <i class="mr-2 fa-regular fa-envelope text-secondary"></i>
              <a href="mailto:info@nlcollege.es">info@nlcollege.es</a>
            </p>
          </div>
        </div>
        <div class="col-lg-3">
          <div
            class="d-flex flex-column align-items-center align-items-lg-end h-100"
          >
            <div class="text-center text-lg-right">
              <p class="fw-600">{{ $lang("footer.follow_us", true) }}</p>
              <p class="small">
                {{ $lang("footer.follow_us_subtitle", true) }}
              </p>
            </div>

            <div class="social-buttons mt-lg-auto mb-lg-0 mt-4 mb-6 small">
              <a
                href="https://www.instagram.com/nlcollege.spain"
                target="_blank"
                class="btn btn-rounded btn-primary"
              >
                <i class="fa-brands fa-instagram"></i>
              </a>
              <a
                href="https://www.youtube.com/c/NLCollegeSpain"
                target="_blank"
                class="btn btn-rounded btn-primary"
              >
                <i class="fa-brands fa-youtube"></i>
              </a>
              <a
                href="https://www.linkedin.com/company/nlcollege-spain"
                target="_blank"
                class="btn btn-rounded btn-primary"
              >
                <i class="fa-brands fa-linkedin"></i>
              </a>
              <a
                href="https://www.facebook.com/nlcollege/"
                target="_blank"
                class="btn btn-rounded btn-primary"
              >
                <i class="fa-brands fa-facebook-f"></i>
              </a>
              <a
                href="https://www.tiktok.com/@nlcollege.spain"
                target="_blank"
                class="btn btn-rounded btn-primary"
              >
                <i class="fa-brands fa-tiktok"></i>
              </a>
            </div>
          </div>
        </div>
        <div class="col-lg-3">
          <div class="d-flex flex-column text-center text-lg-right">
            <p class="fw-600">{{ $lang("footer.keep_in_touch", true) }}</p>
            <p class="small">
              {{ $lang("footer.keep_in_touch_subtitle", true) }}
            </p>

            <a
              :href="$lang('footer.subscribe_link', true)"
              target="_blank"
              class="btn btn-primary small mt-lg-auto mb-lg-0 my-4 mr-lg-0"
            >
              {{ $lang("footer.subscribe", true) }}
            </a>
          </div>
        </div>
      </div>
      <div
        class="d-flex justify-content-between align-items-center align-items-md-end py-4 gap-3 flex-column flex-md-row"
      >
        <router-link class="brand" to="/">
          <img src="/svgs/logo.svg" alt="" />
        </router-link>

        <small>
          <a
            href="/files/Aviso Legal - NL College.pdf"
            target="_blank"
            class="text-secondary mr-3"
          >
            {{ $lang("footer.legal_disclaimer", true) }}
          </a>

          Copyright © NL College 2024
        </small>
      </div>
    </div>
    <div class="container" v-else>
      <div class="details flex-row-desktop mt-3">
        <div class="brand mb-3 mb-md-0">
          <img src="/svgs/logo.svg" alt="" />
        </div>
        <div class="copyright">
          <a
            href="/files/Aviso Legal - NL College.pdf"
            target="_blank"
            class="text-secondary"
          >
            <small>{{ $lang("footer.legal_disclaimer", true) }}</small>
          </a>
          <small>Copyright © NL College 2024</small>
        </div>
      </div>
    </div>
  </footer>
</template>
