<template>
  <div class="floating-btn">
    <a class="whatsapp-button" target="_blank" :href="`/whatsapp?n=${number}`">
      <i class="fa-brands fa-whatsapp"></i>
    </a>
  </div>
</template>

<script>
export default {
  computed: {
    number() {
      return "34664232756";
    },
  },
};
</script>

<style scoped lang="scss">
.floating-btn {
  --btn-radius: 60px;
  position: fixed;
  bottom: 20px;
  right: 20px;
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  z-index: 10000;
  max-width: var(--btn-radius);

  .whatsapp-button {
    background: limegreen;
    height: var(--btn-radius);
    width: var(--btn-radius);
    min-width: var(--btn-radius);
    border-radius: 50%;
    animation: floating-btn 2s infinite;
    display: grid;
    place-items: center;
    font-size: 2em;
    color: white;
    margin-left: 0.5em;
  }
}
@keyframes floating-btn {
  0%,
  100% {
    filter: drop-shadow(0 0 10px limegreen);
  }
  50% {
    filter: drop-shadow(0 0 10px transparent);
  }
}
</style>
